import * as React from "react"
import {Link} from "gatsby"
import '../styles/components/header.css'
import $ from 'jquery'

function handleOverflow() {
    $('#checkboxMenu').toggleClass('checked')
    if ($('#checkboxMenu').hasClass('checked')) {
        $('html').addClass('noscroll')
        $('#menu').removeClass('d-none')
        console.log(true)
    } else {
        $('html').removeClass('noscroll')
        $('#menu').addClass('d-none')
        console.log(false)
    }
}

function handleCheck() {
    if (typeof document !== 'undefined') {
        $(document).ready(() => {
            if ($('#checkboxMenu').hasClass('checked')) {
                $('html').addClass('noscroll')
            } else {
                $('html').removeClass('noscroll')
            }
        })
    }
}

const Header = ({data}) => {
    const header = data.strapiHeader
    const themeChooser = data.strapiThemeChooser
    return (
        <>
            {
                handleCheck()
            }
            <div className={'container-header nav-desktop'}>
                <section key={header.id} className={'d-flex container-fluid'}>
                    <Link to={"/"}>
                        <img src={`${header.Logo.publicURL}`} className={'logo-ebisu'} alt={'logo entreprise'}
                             width={'150px'}/>
                    </Link>
                    <nav className={'d-flex full-width align-items-center'}>
                        <ul className={'row full-width justify-content-center'} style={{listStyle: 'none'}}>
                            {
                                header.ItemsNav.map((item) => (
                                    <li key={item.Item}><Link
                                        to={item.Item.toLowerCase() === 'home' ? `/` : `/${item.Item.toLowerCase()}`}
                                        className={'item-nav-header pr-5'}
                                        style={{textDecoration: "none"}}>{item.Item.toUpperCase()}</Link></li>
                                ))
                            }
                        </ul>
                    </nav>
                </section>
            </div>
            <section className={'burger-menu'}>
                <Link to={"/"} className={'logo-phone d-flex justify-content-center'}>
                    <img src={`${header.Logo.publicURL}`} className={'logo-ebisu'} alt={'logo entreprise'}
                         width={'150px'}/>
                </Link>
                <nav role="navigation">
                    <div id="menuToggle">
                        <input type="checkbox" id={'checkboxMenu'} onClick={e => handleOverflow()}/>
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul className={'d-none'} id="menu">
                            {
                                <div className={'mt-5'}>
                                    <li>
                                        <Link
                                            to={header.ItemsNav[0].Item.toLowerCase() === 'home' ? `/` : `/${header.ItemsNav[0].Item.toLowerCase()}`}
                                            className={'item-nav-header pr-5'}
                                            style={{textDecoration: "none"}}>{header.ItemsNav[0].Item.toUpperCase()}
                                        </Link>
                                        <ul>
                                            {themeChooser.Themes.map(chooser => (
                                                <li key={chooser.id}>
                                                    <Link
                                                        to={`/${chooser.slug}`}
                                                        className={'item-nav-header pr-5'}
                                                        style={{textDecoration: "none"}}>
                                                        {chooser.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li key={header.ItemsNav[1].id}>
                                        <Link
                                            to={`/${header.ItemsNav[1].Item.toLowerCase()}`}
                                            className={'item-nav-header pr-5'}
                                            style={{textDecoration: "none"}}>{header.ItemsNav[1].Item.toUpperCase()}
                                        </Link>
                                    </li>
                                </div>
                            }
                        </ul>
                    </div>
                </nav>
            </section>
        </>
    )
}
export default Header
